import { AxiosError } from 'axios'

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore()
  const mypageStore = useMyPageStore()

  let accessUserSn: number | null = null

  try {
    if (!mypageStore.homeProfile) {
      const { userSn } = await mypageStore.fetchHomeProfile({
        userNcnm: String(to.params.id).replace('@', ''),
      })
      accessUserSn = userSn
    } else {
      accessUserSn = mypageStore.homeProfile.userSn
    }
  } catch (err) {
    if (err instanceof AxiosError) {
      throw showError({
        statusCode: err.response?.status,
        statusMessage: err.message,
      })
    } else {
      throw showError({
        statusCode: 500,
        statusMessage: 'user page access fail',
      })
    }
  }

  if (process.client) {
    const accessAble = userStore.user && userStore.user.userSn === accessUserSn

    if (!accessAble) {
      throw showError({
        statusCode: 403,
        statusMessage: 'user page access require login',
      })
    }
  }
})
